.App {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

li {
  list-style-type: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

nav {
  display: flex;

}
